.Navbar {
  background-color: #ffffff;
}

.Navbar_Menu_Items {
  display: flex;
  align-items: center;

  list-style-type: none;
}

.Navbar_Menu_Items li {
  font: bold;
  font-size: 14px;
  line-height: 16.41px;
}

.avtarCursor {
  cursor: pointer;
  border: 2px solid #fcc314 !important;
}
.navbar_arrow_dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}
.active {
  color: red;
}

.Navbar_right_items {
  display: flex;
  align-items: center;
}
.Navbar_item1 {
  margin: 10px 15px 10px 2px;
}
.Navbar_item {
  font-family: mb;
  font-size: 14px;
  line-height: 16.41px;
  color: #1a1a1a;
}

.Navbar_alert {
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%) !important;
}

.Navbar_alert_text {
  font-family: msb;
  text-align: center;
  font-size: 13px;
  line-height: 15.23px;
}

.Navbar_alert_close_button {
  margin-left: 312px;
  margin-top: 7px !important;
}

.navbar-collapse {
}
.profile_box {
  z-index: 100;
  position: absolute;
  top: 59px;
  right: 8%;
  width: 241px;
  background-color: white;
}

.profile_popup_titte {
  font-size: 13px;
  font-family: msb;
  line-height: 15.23px;
}

.aboutus {
  cursor: pointer;
  color: black;
  font-size: 13px;
  font-family: msb;
  line-height: 15.23px;
}

.profile_path {
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 1px;
  font-size: 12px;
  font-family: msb;
  line-height: 14.06px;
  width: fit-content;
  padding: 7px 11px 7px 11px;
}
.navlik_profile {
  text-decoration: none !important;
}

/* footer  */
.footer {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: relative;
}

.rights {
  font-family: mm;
  font-size: 13px;
  line-height: 16.41px;
  color: #909090;
}

.Up_Menu_Arrow {
  position: absolute;
  bottom: 59px;
  z-index: 1;
  right: 23px;
}
.alert {
  margin-bottom: 0px !important;
}

/* search */
.searchbox {
  padding: 20px;
  border-radius: 6px;
  z-index: 2;
  height: 255px;
  top: 62px;
  position: absolute;
  width: 25%;
  background-color: #ffffff;
  overflow-y: scroll;
  right: 178px;
}

.search_font {
  font-family: gb;
  font-size: 20px;
}

.logoutpopup {
  border-top: 5px solid #f65757;
  border-radius: 5px;
  background-color: #f4f4f4;
  width: 389px;
  height: 303px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1 !important;
}
.Version {
  font-family: mm;
  font-size: 14px;
  line-height: 16px;
  color: #909090;
}

.logout_content {
  font-size: 18px;
  font-family: msb;
  width: 70%;
  text-align: center;
}

.logout_yes {
  width: 164px;
  height: 48px;
  border: none;

  margin-right: 14px;
  color: white;
  background: #f65757;
  border-radius: 1px;
  font-size: 15px;
  font-family: msb;
}

.logout_No {
  width: 164px;
  height: 48px;
  border: none;
  border-radius: 1px;
  margin-right: 14px;

  font-size: 15px;
  font-family: msb;
}

.close_btn_profile {
  background-color: transparent;
}
.arrow_icon {
  cursor: pointer;
}

.notification_container {
  position: relative;
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 6px;
}
.notify {
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 50%;
  background: #f5c421;
  padding: 3px 7px 3px 7px;
  font-family: mb;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
}
.notificationContainer {
  position: absolute;
  background-color: #ffffff;
  width: 441px;
  right: 14%;
  top: 51px;
  z-index: 10000;
  height: 500px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 8px 15px -3px rgb(0 0 0 / 25%);
  border-radius: 2px;
}

.checkbox {
  display: none;
}

.slider0 {
  width: 50px;
  height: 20px;
  background-color: lightgray;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.slider0::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(-30px);
  border-radius: 20px;
  transition: 0.3s;
  box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked ~ .slider0::before {
  transform: translateX(30px);
  box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked ~ .slider0 {
  background-color: #3add5e;
}

.checkbox:active ~ .slider0::before {
  transform: translate(0);
}
.Notification {
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #0e0e0e;
}
.tabsection {
  margin-top: 10px;
  padding: 5px 0px 6px 0px;
  border-bottom: 1px solid #d7d7d7;
  text-align: center;
}
.date_notification {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #919191;
}
.activetabsection {
  margin-top: 10px;
  padding: 5px 0px 6px 0px;
  border-bottom: 1px solid black;
  text-align: center;
}
.maincontent {
  flex: 1;
}
.maincontent_text {
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #0e0e0e;
}
.maincontent_subtext {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #919191;
}
.notifycontainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: red;
  position: absolute;
  top: 0px;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}
@media only screen and (max-width: 600px) {
  .footer {
    display: none;
  }
  #navbar_sm {
    display: none;
  }
}
