* {
  margin: 0;
}

@font-face {
  font-family: "msb";
  src: local("msb"),
    url("../src/Assets/Fonts/Mulish-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "mb";
  src: local("mb"),
    url("../src/Assets/Fonts/Mulish-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "mm";
  src: local("mm"),
    url("../src/Assets/Fonts/Mulish-Medium.ttf") format("truetype");
}
.Privacy {
  color: black !important;
  text-decoration: underline !important;
}
