.support_home {
  background-color: #f8f8f8;
  width: 100%;
  padding-top: 20px;
  height: 100%;
  padding-bottom: 5%;
}
.support_home_container {
  padding: 20px;
  margin: 35px auto 0px;
  width: 80%;
  background: linear-gradient(99.98deg, #646464 4.09%, #0e0e0e 100.77%);
  border-radius: 1px;
}
.support_flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 98px;
}
.ui_container_main_text {
  font-family: mb;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}
.loderimg {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 50%;
  z-index: 2;
}
.bulkimg_img_conatiner {
  border-radius: 3px;
  /* border: 1px solid lightgrey; */
  width: 100%;
  height: 100%;
}

.ui_container_main_subtext {
  font-family: mb;
  font-size: 17px;
  line-height: 20px;
  color: #a8a8a8;
}
.tabs_container {
  margin: 31px auto 0px;
  background-color: #ffffff;

  width: 80%;
  padding: 20px;
}
.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.Signing_Up_KYC {
  grid-area: 1 / 1 / 2 / 2;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  width: 100%;
  height: 100px;
}
.Signing_Up_KYC_subtext {
  margin-left: 10px;
  font-family: mb;
  font-size: 12px;
  line-height: 14px;
  color: #969696;
}
.Signing_Up_KYC_text {
  margin-bottom: 4px;
  margin-left: 10px;
  font-family: mb;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}
.Signing_Up_subKYC {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 22px 0px 28px 23px;
}
.GeneralQuery {
  grid-area: 1 / 2 / 2 / 3;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  width: 100%;
  height: 100px;
}
.Security {
  grid-area: 1 / 3 / 2 / 4;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  width: 100%;
  height: 100px;
}
.Chat_With_Us {
  grid-area: 2 / 1 / 3 / 2;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  width: 100%;
  height: 100px;
}
.CallNow {
  grid-area: 2 / 2 / 3 / 3;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  width: 100%;
  height: 100px;
}
.div6 {
  grid-area: 2 / 3 / 3 / 4;
  background-color: #ffffff;
}
.link_tag {
  text-decoration: none;
}
.breadcrumb {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.single_suport {
  background-color: #f8f8f8;
  width: 100%;
  height: fit-content;
  padding-bottom: 35px;
}
.arrow {
  width: 18px;
}
.single_suport_container {
  margin: 0px auto 0px;
  width: 80%;
  background-color: transparent;
  padding-top: 20px;
}
.single_suport_title_container {
  width: 100%;
  height: 55px;
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 1px;
}
.single_suport_txt {
  font-family: mb;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  padding: 19px 0px 0px 32px;
}
.backbutton {
  position: absolute;
  font-family: mb;
  left: 114px;
  text-decoration: underline;
}
.error {
  font-family: msb;
  font-size: 12px;
  color: red;
}

.breadcrumb_txt {
  font-family: msb;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #969696;
}
.breadcrumb_txt:hover {
  font-family: msb;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #969696;
}
.breadcrumb_subtxt {
  font-family: msb;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.help {
  display: flex;
  justify-content: space-between;
}
.chatwith {
  width: max-content;
}
.single_suport_content {
  margin: 0px auto 0px;
  /* width: 96%; */
  background-color: #ffff;
  padding: 20px 19px 20px 21px;
}
.single_suport_content_txt {
  font-family: msb;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
.call {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: msb;
}
.suport_bar {
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 1px;
  margin-top: 8px;
  padding: 18px 0px 18px 28px;
  font-family: msb;
}
.suport_footer_content {
  margin-top: 20px;

  background: #ffefbb;
  padding: 19px 18px 19px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "msb";
}
.col_one {
  padding: 20px;
  background-color: #ffffff;
  width: -webkit-fill-available;
}
.call_now {
  background: #ffffff;
  border-radius: 1px;
  padding: 8px 11px 9px 11px;
  border: none;
  width: 100%;
  margin-right: 10px;
}
.logosvg {
  padding: 0px 5px 0px 0px;
}
.logos_whatsapp_icon {
  background: #fcc200;
  border-radius: 1px;
  padding: 6px 11px 9px 11px;
  border: none;
  width: 100%;
}

.col_two {
  background-color: #ffffff;
  width: -webkit-fill-available;
  padding: 20px;
}
.main_col {
  width: 100%;
  display: flex;
}
.individualleads {
  margin-top: 30px;
  width: 40%;
}
.individualleads2 {
  width: 57%;
}
.col_one_text {
  font-family: mb;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  margin: 0px;
  /* or 169% */

  /* Black */

  color: #0e0e0e;
}
.col_one_subtext {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 27px;
  margin: 0px;
  /* or 169% */

  /* Black */

  color: #909090;
}
.col_two_text {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  /* Dark Gray */

  color: #919191;
}
.col_main_text {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  /* Black */

  color: #0e0e0e;
}
.col_main {
  background-color: #ffffff;
}

/* .individualleads2 {
  width: 100%;
} */
.mobile {
  display: none;
}
.content_web {
  padding: 27px 22px 27px 22px;
  background: white;
}
.dataaloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.web_img_one {
  object-fit: contain;
  width: 100%;
  margin-top: 27px;
}
.web_img_two {
  object-fit: contain;
  width: 100%;
}

.web_textcenter {
  margin: 20px 0px 20px 0px;
}
.apiTicket {
  /* width: 335px; */
  height: 125px;

  background: #f6f6f6;
  border-radius: 2px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 18px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  width: 67%;
}
.ticketCard {
  padding: 19px 14px;
  /* height: 125px; */
  background: #f6f6f6;
  border-radius: 2px;
  cursor: pointer;
}
.ticketBTN {
  background: #38d65b;
  border-radius: 1px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  font-family: mb;
  color: white;
  font-size: 13px;
  height: 30px;
  width: fit-content;
}
.ticketBTNtable {
  background: #38d65b;
  border-radius: 1px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  font-family: mb;
  color: white;
  font-size: 13px;
  height: 30px;
  width: 55%;
  text-align: center;
}
.ticketBTN_text {
  margin-top: 10px;
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
}
.ticketBTN_subtext {
  font-family: mb;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #151414;
  cursor: pointer;
}
.titleticket {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.YourTickets {
  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}
.YourTicketslink {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #919191;
  cursor: pointer;
}
.SingleViewCardweb {
  background: #f8f8f8;
  width: 100%;
  height: 100%;
  padding-bottom: 22%;
}
.innercontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableHead {
  font-family: msb;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: start;
  color: #1a1a1a;
  padding: 14px 10px;
  background: #fff9e5;
}
.innercontent_subtext {
  font-family: msb;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;

  color: #000000;
}
.backBtnVue {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.backBtnTxt {
  font-family: msb;
  font-size: 16;
  align-items: center;
  margin-bottom: 0px;
  cursor: pointer;
}
.Opened {
  background: #e83a3a;
  border-radius: 1px;
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px 13px;
  width: -moz-fit-content;
  width: fit-content;
  height: fit-content;
}
.Openedtable {
  background: #e83a3a;
  border-radius: 1px;
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px 13px;
  width: 55%;
  text-align: center;
}
.onetablebody {
  background: #ffffff;
  border-bottom: 2px solid #f2f1f1;
  border-radius: 3px;
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1a1a1a;
  padding: 10px 8px;
}
.innercontent_container {
  background: #ffffff;
  padding: 18px;
}
.contentSingle {
  font-family: msb;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #919191;
}
.DocumentName {
  font-family: mb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
}
.DocumentNamesubView {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #8c8c8c;
}

.DocumentName_conteiner {
  display: flex;
  align-items: center;
}
.Resolving {
  background: #f57315;
  border-radius: 1px;
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  padding: 6px 11px;
  height: fit-content;
  width: fit-content;
}
.Resolvingtable {
  background: #f57315;
  border-radius: 1px;
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  padding: 6px 11px;
  height: fit-content;
  width: 55%;
  text-align: center;
}
.Resolving_content {
  background: #f8f8f8;
  border-radius: 2px;
  padding: 20px;
  /* margin: 0px 15px; */
}
.Resolving_content_text {
  font-family: msb;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  /* margin-top: 10px; */
  /* identical to box height */

  color: #000000;
}
.docuImg {
  width: 4%;
}
.docuImglogo {
  width: 10%;
}
.SingleViewCardmobile {
  display: none;
}

/* input search */
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.search__input {
  font-family: msb;
  font-size: 12px;
  background-color: #f4f2f2;
  border: none;
  color: #646464;
  padding: 0.7rem 1rem;
  border-radius: 1px;
  width: 100%;
  transition: all ease-in-out 0.5s;
  margin-right: -2rem;
}

/* .search__input:hover,
.search__input:focus {
  box-shadow: 0 0 1em #00000013;
} */

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
}
.mobileview {
  display: none;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  background-color: #f0eeee;
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  margin-top: 0.1em;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
}

.popupbackground {
  height: 100vh;
  width: 100%;
  background: rgba(14, 14, 14, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.view_arrow:hover {
  cursor: pointer;
  color: #f7b314;
}
.popupbackgroundmobile {
  height: 100vh;
  width: 100%;
  background: rgba(14, 14, 14, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.popup {
  background: #ffffff;
  border-radius: 4px;
  width: 40%;
}
.FullName {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #0e0e0e;
}
.inputtag {
  background: #f6f6f6;
  border-radius: 2px;
  outline: none;
  border: none;
  padding: 9px 9px;
  font-family: msb;
  font-size: 14px;
}
.inputtagedit {
  outline: none;
  border: none;
  padding: 9px 0px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #969696;
  padding: 14px 8px;
}
.uplode_file_dot_border {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff9e5;
  border: 1px dashed #fcc200;
  width: 100%;
  height: 55.6px;
}
.uplode_file_text {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #0e0e0e;
}
.submitbutton {
  background: linear-gradient(91.1deg, #ffe185 6.02%, #f7ba00 105.47%);
  border-radius: 1px;
  height: 46px;
  width: 100%;
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
}
.titlepopup {
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  color: #000000;
}

/* Media Query */
@media only screen and (max-width: 600px) {
  .ui_container_main_text {
    font-family: lb;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;

    color: #ffffff;
  }
  .support_home_container,
  .tabs_container {
    width: 90%;
    padding-bottom: 10px;
  }
  .Resolving_content {
    margin: 0px 15px;
  }
  .popup {
    height: 200px;
  }
  .popupbackground,
  .Chat_With_Us {
    display: none;
  }
  .support_home {
    padding-top: 1px;
    padding-bottom: 30%;
  }
  .wave {
    width: 70px;
  }
  .breadcrumb {
    display: none;
  }
  .mobileview {
    display: block;
    padding: 5px 19px;
    margin-bottom: 18%;
  }
  .footer {
    display: none;
  }
  .DocumentName {
    font-size: 13px;
  }
  .ticketBTN_text {
    font-size: 13px;
  }
  .contentSingle {
    font-size: 13px;
  }
  .innercontent_subtext {
    font-size: 18px;
  }
  .ticketBTN_text {
    font-size: 13px;
  }
  .docuImg {
    width: 18%;
  }
  .SingleViewCardmobile {
    display: block;
    background: white;
    width: 100%;
    height: 100%;
    padding-bottom: 25%;
  }
  .SingleViewCardmobile_content {
    padding: 20px;
    background: #f6f6f6;
    border-radius: 2px;
    width: 90%;
    margin: auto;
  }

  .SingleViewCardweb {
    display: none;
  }
  .docuImglogo {
    width: 20%;
  }
  .ticketCard {
    margin-bottom: 20px;
  }
  .single_suport_container {
    width: 90%;
  }
  .Signing_Up_KYC,
  .GeneralQuery,
  .Security,
  .Chat_With_Us,
  .CallNow {
    /* background-color: ; */
    margin-bottom: 20px;
    /* border-bottom: 0.2px solid black; */
  }
  /* .suport_footer_content {
    padding: 19px 18px 19px 19px;
  } */
  .web {
    display: none;
  }
  .suport_footer_content {
    display: none;
  }
  .mobile {
    display: block;
  }
  .breadcrumb,
  .backBtnVue {
    display: none;
  }
  /* .call_now,
  .logos_whatsapp_icon {
    display: none;
  } */

  .text_help {
    text-align: center;
    font-size: 15px;
  }
  .help {
    margin-top: 10px;
    display: block;
    width: 100%;
    /* margin: 9px 0px 1px 21px; */
  }
  .col_one {
    width: -webkit-fill-available;
  }
  .main_col {
    display: block;
  }
  .individualleads {
    width: 64%;
  }
  .col_two {
    padding: 0px 0px 20px 17px;
  }
  .col_one_text {
    font-family: msb !important;
  }
  .search__input {
    margin-right: -6rem;
  }
  .search__button {
    margin-top: 0px !important;
  }

  .individualleads {
    margin: 0px;
  }
  .individualleads2 {
    width: 100%;
  }
  .parent {
    margin-top: 12px;
  }
  .call_now {
    margin-bottom: 10px;
    width: 100%;
  }
  /* .col_one {
    width: 100%;
  }
  .col_two {
    width: 100%;
  } */
  .single_suport {
    background-color: #f8f8f8;
    width: 100%;
    height: fit-content;
    padding-bottom: 85px;
  }
  .ui_container_main_subtext {
    font-family: gm;
    font-size: 14px;
    line-height: 20px;
    color: #a8a8a8;
  }
  .innercontent_container {
    background: #ffffff;
    padding: 0px;
  }
  .parent {
    display: block;
  }
  .backbutton {
    display: none;
  }
}
